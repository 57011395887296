import { Navigate, Outlet } from "react-router-dom";
import { useProviderContext } from "../provider/appProvider";

function ProtectedRoute(props) {
  const {
    state: { admin },
  } = useProviderContext();

  return admin ? <Outlet /> : <Navigate to="/login" replace />;
}

export default ProtectedRoute;
