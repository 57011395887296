import React from "react";
import {
  AiOutlineAim,
  AiOutlineBook,
  AiOutlineDashboard,
  AiOutlineDatabase,
} from "react-icons/ai";

function ExamStaticsBox({
  title = "",
  main = 0,
  model = 0,
  others = 0,
  className = "",
}) {
  return (
    <div className={"exam-statics-box"}>
      <h2 className={`exam-statics-title ${className}`}>{title}</h2>
      <h6 className="exam-statics-data">
        <AiOutlineBook className="exam-stat-title-main" />
        <span className="exam-stat-title-main">Main: </span>
        <span className="exam-stat-number">{main}</span>
      </h6>
      <h6 className="exam-statics-data">
        <AiOutlineDashboard className="exam-stat-title-model" />
        <span className="exam-stat-title-model">Model: </span>
        <span className="exam-stat-number">{model}</span>
      </h6>
      <h6 className="exam-statics-data">
        <AiOutlineAim className="exam-stat-title-others" />
        <span className="exam-stat-title-others">Others: </span>
        <span className="exam-stat-number">{others}</span>{" "}
      </h6>
      <h6 className="exam-statics-data">
        <AiOutlineDatabase className="exam-stat-title-main" />
        <span className="exam-stat-title-main">Total: </span>
        <span className="exam-stat-number">{others + main + model}</span>{" "}
      </h6>
    </div>
  );
}

export default ExamStaticsBox;
