import React, { useEffect } from "react";
import "./payment-detail.css";
import {
  BsFillMenuButtonWideFill,
  BsAlarm,
  BsStopCircle,
} from "react-icons/bs";
import moment from "moment";

import { AiOutlineReload, AiOutlineUser } from "react-icons/ai";
import { useProviderContext } from "../../provider/appProvider";
import Loader from "../../common/Loader";
import BasicTitle from "../../common/BasicTitle";
import { useNavigate } from "react-router-dom";
function PaymentDetail(props) {
  const {
    state: { paymentDetail },
    fetchPaymentData,
  } = useProviderContext();

  const refreshData = () => {
    fetchPaymentData();
  };
  useEffect(() => {
    fetchPaymentData();
  }, [fetchPaymentData]);

  return (
    <div className="payment-detail-page">
      <div className="payment-detail-content-wrapper">
        <div className="payment-detail-header">
          <h1 className="common-pages-title"> Payment detail </h1>
          <div className="payment-detail-refresh-wrapper">
            <button
              className="payment-detail-refresh-icon-button"
              onClick={refreshData}
            >
              Refresh
              <AiOutlineReload className="payment-detail-refresh-icon" />
            </button>
            <BsFillMenuButtonWideFill className="payment-detail-icon" />
          </div>
        </div>

        {paymentDetail == null ? (
          <div className="page-loading-wrapper">
            <Loader width={200} height={200} color={"red"} />
          </div>
        ) : paymentDetail.length === 0 ? (
          <div className="page-loading-wrapper">
            <div className="no-data-wrapper">
              <BsFillMenuButtonWideFill className="no-data-icon" />

              <BasicTitle title="There is no payment detail in the docket" />
            </div>
          </div>
        ) : (
          paymentDetail
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((detail) => {
              return <PaymentDetailCard key={detail.id} detail={detail} />;
            })
        )}
      </div>
    </div>
  );
}

export default PaymentDetail;

function PaymentDetailCard({
  detail: { fullname, phone, bank, plan, createdAt, id, isRenewing },
}) {
  console.log(isRenewing);
  const navigator = useNavigate();
  const timing = moment(createdAt).fromNow();
  return (
    <div
      className="payment-detail-card"
      onClick={() => navigator("/payment-details/" + id)}
    >
      <div className="upper-payment-card">
        <h5 className="payment-card-name">
          <AiOutlineUser className="payment-card-name-icon" />
          {fullname}
          <span className="payment-card-grade">
            {" "}
            Grade:{" "}
            {plan === "grade8"
              ? "8"
              : plan === "grade12n"
              ? "12 Natural"
              : plan === "grade12s"
              ? "12 Social"
              : plan}{" "}
            <BsStopCircle className="payment-card-grade-icon" />{" "}
          </span>
        </h5>
        <div className="payment-card-time">
          {" "}
          <BsAlarm className="payment-card-time-icon" /> {timing}
        </div>
      </div>
      <div className="bottom-payment-card">
        <div className="payment-card-phone">{phone}</div>

        <div className="payment-card-bank">{bank}</div>
      </div>
      {isRenewing ? <div className="renewing-tag"> Renewing </div> : null}
    </div>
  );
}
