const { ADMIN_STORAGE_KEY } = require("../../global");

function getAdmin() {
  try {
    const admin = JSON.parse(localStorage.getItem(ADMIN_STORAGE_KEY));
    return admin;
  } catch (err) {
    return null;
  }
}
export default getAdmin;
