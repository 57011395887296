import React from "react";
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Loader from "../../common/Loader";
import BasicTitle from "../../common/BasicTitle";
import {
  AiFillBook,
  AiFillPhone,
  AiFillUnlock,
  AiOutlineDeleteColumn,
  AiOutlineFieldTime,
  AiOutlineMail,
  AiOutlinePushpin,
  AiOutlineUser,
  AiTwotoneHome,
} from "react-icons/ai";
import moment from "moment";
import "./singlePaymentCard.css";
import axios from "axios";
import { APP_SERVER } from "../../../global";
import { getAccessToken } from "../../util/accessToken";
import { useProviderContext } from "../../provider/appProvider";
import { toast } from "react-toastify";
function SinglePaymentDetail(props) {
  const { paymentId } = useParams();
  const { data, loading, error, refreshData } = useFetch(
    "/admin/api/payment-details/" + paymentId
  );

  return (
    <div className="payment-detail-page">
      <div className="payment-detail-content-wrapper">
        {loading && (
          <div className="page-loading-wrapper">
            <Loader width={200} height={200} color={"#7175D6"} />
          </div>
        )}
        {data && (
          <SinglePamentCard data={data?.payment} refreshData={refreshData} />
        )}
        {error && (
          <div className="page-loading-wrapper">
            <div className="no-data-wrapper">
              <BsFillMenuButtonWideFill className="no-data-icon" />

              <BasicTitle title="Something went wrong!!" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SinglePaymentDetail;

function SinglePamentCard({
  data: {
    user: { fullname, grade, phone, email, createdAt, uid, subscription },
    createdAt: paidAt,
    plan,
    bank,
    id,
    img,
  },
  refreshData,
}) {
  const { fetchPaymentData } = useProviderContext();
  const navigator = useNavigate();
  const { logoutAdmin } = useProviderContext();
  const accessToken = getAccessToken();
  if (!accessToken) {
    logoutAdmin();
  }

  const handleVerification = async () => {
    try {
      await axios.post("/admin/api/verify-payment", {
        paymentId: id,
        plan,
      });
      refreshData();
    } catch (err) {}
  };

  const handleRejectingPayment = async () => {
    try {
      await axios.post("/admin/api/reject-payment", { paymentId: id });
      await fetchPaymentData();
      toast.success("Payment successfully rejected!!");
      navigator("/");
    } catch (err) {
      toast.error(err?.response?.data?.message ?? "Something went wrong!!");
    }
  };

  const handleNavigation = (uid) => {
    navigator("/users/" + uid);
  };

  return (
    <div className="single-payment-card">
      <div className="single-payment-card-profile-wrapper">
        <div className="profile-avatar-wrapper">
          <AiOutlineUser className="profile-avatar" />
          <div className="profile-fullname">{fullname}</div>
        </div>
        <div className="profile-grade">{grade}</div>
      </div>

      <div className="single-payment-data">
        <BasicTitle title={"Payment data"} />
        <Tile
          title={"paid at"}
          info={moment(paidAt).fromNow()}
          icon={<AiOutlineFieldTime className="tile-icon" />}
        />
        <Tile
          title={"Plan"}
          info={
            plan === "grade12n"
              ? "Grade 12 natural"
              : plan === "grade12s"
              ? "Grade 12 Social"
              : plan === "grade8"
              ? "Grade 8"
              : plan
          }
          icon={<AiFillBook className="tile-icon" />}
        />
        <Tile
          title={"Bank"}
          info={bank}
          icon={<AiTwotoneHome className="tile-icon" />}
        />

        <div className="payment-image">
          {" "}
          <BasicTitle title={"Screenshot"} />{" "}
          <div className="payment-image-wrapper">
            <img
              src={`${APP_SERVER}/admin/payemnt-verification/${img}?accessToken=${getAccessToken()}`}
              alt="payment screenshot"
              className="payment-screenshot"
              loading="lazy"
            />
          </div>
        </div>

        <div className="payment-verify-wrapper">
          {subscription != null && subscription[plan] ? (
            <button
              className="verified-button"
              onClick={() => handleNavigation(uid)}
            >
              <span className="verify-button-text"> See user </span>
              <AiOutlinePushpin className="verify-button-icon" />
            </button>
          ) : (
            <>
              <button className="verify-button" onClick={handleVerification}>
                <span className="verify-button-text">Verify account</span>
                <AiFillUnlock className="verify-button-icon" />
              </button>
              <button
                className="verify-button payment-delete-button"
                style={{ background: "crimson" }}
                onClick={() => handleRejectingPayment()}
              >
                <span className="verify-button-text">Reject Payment</span>
                <AiOutlineDeleteColumn className="verify-button-icon" />
              </button>
            </>
          )}
        </div>
      </div>

      <div className="profile-info-table">
        <BasicTitle title={"User detail"} />
        <Tile
          title={"phone"}
          info={phone}
          icon={<AiFillPhone className="tile-icon" />}
        />
        <Tile
          title={"Email"}
          info={email}
          icon={<AiOutlineMail className="tile-icon" />}
        />
        <Tile
          title={"Acc. Created At"}
          info={moment(createdAt).fromNow()}
          icon={<AiOutlineFieldTime className="tile-icon" />}
        />
      </div>
    </div>
  );
}

function Tile({ title, info, icon }) {
  return (
    <div className="profile-info-tile">
      <div>
        {icon}
        <span className="tile-title">{title}:</span>
      </div>
      <span className="tile-data"> {info} </span>
    </div>
  );
}
