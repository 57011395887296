import React from "react";
import useAppServerFetch from "../../hooks/useAppServerFetch";
import BasicTitle from "../../common/BasicTitle";
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import Loader from "../../common/Loader";

import "./examStatistics.css";
import ExamStaticsBox from "./ExamStaticsBox";
import stat from "../../util/calcStaticsOfExams";
import { AiOutlineReload } from "react-icons/ai";
import GradeDetail from "./GradeDetail";
function ExamStatistics(props) {
  const { data, loading, error, refreshData } =
    useAppServerFetch("/admin/structure");

  return (
    <div className="payment-detail-page">
      <div className="payment-detail-content-wrapper">
        <div className="user-detail-header">
          <h1 className="common-pages-title"> Exam statistics </h1>
          <div className="user-detail-refresh-wrapper">
            <button
              className="user-detail-refresh-icon-button"
              onClick={refreshData}
            >
              Refresh <AiOutlineReload className="user-detail-refresh-icon" />
            </button>
          </div>
        </div>
        {loading && (
          <div className="page-loading-wrapper">
            <Loader width={200} height={200} color={"#7175D6"} />
          </div>
        )}
        {data && (
          <div>
            <ExamStatisticsBody stat={stat(data)} />
          </div>
        )}
        {error && (
          <div className="page-loading-wrapper">
            <div className="no-data-wrapper">
              <BsFillMenuButtonWideFill className="no-data-icon" />

              <BasicTitle title="Something went wrong!!" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ExamStatistics;

function ExamStatisticsBody({ stat }) {
  return (
    <div style={{ marginTop: "10px" }}>
      <BasicTitle title="Exam Statics" className="tertiary-title" />
      <div className="statics-wrapper">
        <ExamStaticsBox
          className="primary-title"
          title={"All Exams"}
          main={stat?.main?.count}
          model={stat?.model?.count}
          others={stat?.others?.count}
        />
        <ExamStaticsBox
          className="secondary-title"
          title={"Downloaded Exams"}
          main={stat?.main?.download}
          model={stat?.model?.download}
          others={stat?.others?.download}
        />
      </div>
      <BasicTitle title="Update Statics" className="tertiary-title" />
      <div className="statics-wrapper">
        <ExamStaticsBox
          className="primary-title"
          title={"Weekly added Exams"}
          main={stat?.main?.weekly}
          model={stat?.model?.weekly}
          others={stat?.others?.weekly}
        />
        <ExamStaticsBox
          className="secondary-title"
          title={"Monthly added Exams"}
          main={stat?.main?.monthly}
          model={stat?.model?.monthly}
          others={stat?.others?.monthly}
        />
      </div>
      <br />
      <hr />
      <br />
      <GradeDetail stat={stat} grade={"8"} gradeTitle={8} />
      <GradeDetail stat={stat} grade={"10"} gradeTitle={10} />
      <GradeDetail stat={stat} grade={"12n"} gradeTitle={"12 natural"} />
      <GradeDetail stat={stat} grade={"12s"} gradeTitle={"12 social"} />
    </div>
  );
}
