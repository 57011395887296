import React, { useEffect } from "react";
import { BsFillMenuButtonWideFill, BsAlarm } from "react-icons/bs";
import moment from "moment";

import {
  AiOutlineBranches,
  AiOutlineReload,
  AiOutlineUser,
} from "react-icons/ai";
import { useProviderContext } from "../../provider/appProvider";
import Loader from "../../common/Loader";
import BasicTitle from "../../common/BasicTitle";
import { useNavigate } from "react-router-dom";

function BankRequesters(props) {
  const {
    state: { users, activities },
    fetchUsers,
    fetchActivities,
  } = useProviderContext();

  const refreshData = () => {
    fetchUsers();
    fetchActivities();
  };
  useEffect(() => {
    fetchUsers();
    fetchActivities();
  }, [fetchUsers, fetchActivities]);

  return (
    <div className="user-detail-page">
      <div className="user-detail-content-wrapper">
        <div className="user-detail-header">
          <h1 className="common-pages-title"> Bank Requests </h1>
          <div className="user-detail-refresh-wrapper">
            <button
              className="user-detail-refresh-icon-button"
              onClick={refreshData}
            >
              Refresh
              <AiOutlineReload className="user-detail-refresh-icon" />
            </button>
          </div>
        </div>

        {users == null || activities == null ? (
          <div className="page-loading-wrapper">
            <Loader width={200} height={200} color={"red"} />
          </div>
        ) : users.length === 0 ? (
          <div className="page-loading-wrapper">
            <div className="no-data-wrapper">
              <BsFillMenuButtonWideFill className="no-data-icon" />

              <BasicTitle title="There is no user detail in the docket" />
            </div>
          </div>
        ) : (
          activities
            .filter((activity) => activity.bankRequestInformation !== null)
            .sort((a, b) => {
              a = JSON.parse(a.bankRequestInformation);
              b = JSON.parse(b.bankRequestInformation);

              a.sort((a, b) => b - a);
              b.sort((a, b) => b - a);

              a = a[0];
              b = b[0];
              return b - a;
            })
            .map((activity) => {
              const user = users.filter(
                (user) => user?.uid === activity?.uid
              )[0];

              if (user) {
                return (
                  <BankRequester
                    key={user?.uid}
                    user={user}
                    activity={activity}
                  />
                );
              }
              return <> </>;
            })
        )}
      </div>
    </div>
  );
}

export default BankRequesters;

function BankRequester({
  user: { fullname, phone, grade, createdAt, uid, isSubscribed },
  activity,
}) {
  const navigator = useNavigate();
  const bankRequestInformation =
    JSON.parse(activity.bankRequestInformation) ?? [];
  const timing = moment(
    bankRequestInformation.sort((a, b) => b - a)[0]
  ).fromNow();
  return (
    <div
      className="user-detail-card"
      onClick={() => navigator("/users/" + uid)}
    >
      <div className="upper-user-card">
        <h5 className="user-card-name">
          <AiOutlineUser className="user-card-name-icon" />
          {fullname}
          <span className="user-card-grade">
            {" "}
            Grade:
            {grade === "grade8"
              ? "8"
              : grade === "grade12n"
              ? "12 Natural"
              : grade === "grade12s"
              ? "12 Social"
              : grade}{" "}
            <AiOutlineBranches className="user-card-grade-icon" />{" "}
          </span>
        </h5>
        <div className="user-card-time">
          {" "}
          <BsAlarm className="user-card-time-icon" /> {timing}
        </div>
      </div>
      <div className="bottom-user-card">
        <div className="user-card-phone">
          Bank requested count: {bankRequestInformation?.length}
        </div>
        <div className="user-card-email">{phone}</div>
      </div>

      {isSubscribed === true && (
        <div className={"verification-flag verification-flag-" + true}>
          {" "}
          Subscribed
        </div>
      )}
    </div>
  );
}
