import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useProviderContext } from "../provider/appProvider";

function useFetch(url) {
  const { logoutAdmin } = useProviderContext();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const fetchData = useCallback(() => {
    axios
      .get(url)
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);

        if (err?.response?.status === 401 || err?.response?.status === 403) {
          logoutAdmin();
          toast.warn("Your loggin session is expired");
          return;
        }
      });
  }, [url, logoutAdmin]);
  
  const refreshData = () => fetchData();

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [url, fetchData]);

  return { data, loading, error, refreshData };
}

export default useFetch;
