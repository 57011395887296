import "./App.css";
import { ToastContainer } from "react-toastify";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import AdminLogin from "./components/adminLogin/AdminLogin";
import ProtectedRoute from "./components/protected/ProtectedRoute";
import { useProviderContext } from "./components/provider/appProvider";
import PaymentDetail from "./components/screens/PaymentDetails/PaymentDetail";
import SinglePaymentDetail from "./components/screens/SinglePaymentDetail/SinglePaymentDetail";
import AlllUsers from "./components/screens/AllUsers/AlllUsers";
import Singleuser from "./components/screens/SingleUser/SingleUser";
import UserStatistics from "./components/screens/UserStatistics/UserStatistics";
import Admin from "./components/screens/Admin/Admin";
import ExamStatistics from "./components/screens/ExamStatistics/ExamStatistics";
import SubjectExamDetail from "./components/screens/SubjectExamsDetail/SubjectExamDetail";
import AppDownload from "./components/screens/AppDownload/AppDownload";
import TopDownloaders from "./components/screens/TopDownloaders/TopDownloaderUsers";
import RecentDownloaders from "./components/screens/RecentDownloader/RecentDownloader";
import BankRequesters from "./components/screens/BankRequests/BankRequests";
import EmailUsers from "./components/screens/EmailUsers/EmailUsers";

function App() {
  const {
    state: { admin },
  } = useProviderContext();
  return (
    <Router>
      <div className="App">
        {admin ? <Header /> : <> </>}
        <Routes>
          <Route path="/login" element={<AdminLogin />} />
          <Route path="" element={<ProtectedRoute />}>
            <Route path="/" element={<PaymentDetail />} />
            <Route
              path="/payment-details/:paymentId"
              element={<SinglePaymentDetail />}
            />

            <Route path="/users" element={<AlllUsers />} />
            <Route path="/users/:uid" element={<Singleuser />} />

            <Route path="/user-statistics" element={<UserStatistics />} />
            <Route path="/exam-statistics" element={<ExamStatistics />} />

            <Route path="/top-downloaders" element={<TopDownloaders />} />
            <Route path="/recent-downloaders" element={<RecentDownloaders />} />
            <Route path="/bank-requests" element={<BankRequesters />} />
            <Route path="/email-users" element={<EmailUsers />} />

            <Route path="/app-download-statics" element={<AppDownload />} />

            <Route
              path="/subject-exams-detail/:key"
              element={<SubjectExamDetail />}
            />
            <Route path="/admin" element={<Admin />} />
          </Route>
        </Routes>
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
