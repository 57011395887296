import { toast } from "react-toastify";
import { ADMIN_STORAGE_KEY } from "../../global";
import axios from "axios";
import { removeAccessToken } from "./accessToken";
export const logoutAdminTotally = async () => {
  try {
    await axios.get("/admin/api/logout");
  } catch (err) {
    toast.error("Error in logging out.");
  }
  localStorage.removeItem(ADMIN_STORAGE_KEY);
  removeAccessToken();
};
