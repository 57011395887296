import React from "react";
import "./common.css";
import { InfinitySpin } from "react-loader-spinner";

function Loader({ width, height, color }) {
  return (
    <div className="loading-wrapper" width={width} height={height}>
      <InfinitySpin
        // type="TailSpin"
        color={color ?? "white"}

        // width="80px"
      />
    </div>
  );
}

export default Loader;
