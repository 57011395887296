import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { getAccessToken } from "../util/accessToken";
import { APP_SERVER } from "../../global";
import { useProviderContext } from "../provider/appProvider";
import { toast } from "react-toastify";

function useAppServerFetch(url) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { logoutAdmin } = useProviderContext();
  const fetchData = useCallback(() => {
    axios
      .get(APP_SERVER + url, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": "application/json", // Adjust the content type as needed
        },
      })
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          logoutAdmin();
          toast.warn("Your loggin session is expired");
          return;
        }

        toast.error(err.message);
      });
  }, [url, logoutAdmin]);
  const refreshData = () => fetchData();

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [url, fetchData]);

  return { data, loading, error, refreshData };
}

export default useAppServerFetch;
