import React from "react";
import {
  AiOutlineAim,
  AiOutlineBook,
  AiOutlineDashboard,
  AiOutlineDatabase,
  AiOutlineDownload,
} from "react-icons/ai";

function GradeStaticsBox({
  title = "",
  main = 0,
  model = 0,
  others = 0,
  className = "",
  mainDownload = 0,
  modelDownload = 0,
  othersDownload = 0,
}) {
  return (
    <div className={"exam-statics-box"}>
      <h2 className={`exam-statics-title ${className}`}>{title}</h2>
      <div className="row-maker">
        <div>
          <h6 className="grade-exam-statics-data">
            <AiOutlineBook className="exam-stat-title-main" />
            <span className="exam-stat-title-main">Main: </span>
            <span className="exam-stat-number">{main}</span>
          </h6>
          <h6 className="grade-exam-statics-data">
            <AiOutlineDashboard className="exam-stat-title-model" />
            <span className="exam-stat-title-model">Model: </span>
            <span className="exam-stat-number">{model}</span>
          </h6>
          <h6 className="grade-exam-statics-data">
            <AiOutlineAim className="exam-stat-title-others" />
            <span className="exam-stat-title-others">Others: </span>
            <span className="exam-stat-number">{others}</span>{" "}
          </h6>
          <h6 className="grade-exam-statics-data">
            <AiOutlineDatabase className="exam-stat-title-main" />
            <span className="exam-stat-title-main">Total: </span>
            <span className="exam-stat-number">
              {others + main + model}
            </span>{" "}
          </h6>
        </div>
        <div>
          <h6 className="grade-exam-statics-data">
            <AiOutlineDownload className="exam-stat-title-main" />
            <span className="exam-stat-title-main">Main Download: </span>
            <span className="exam-stat-number">{mainDownload}</span>
          </h6>
          <h6 className="grade-exam-statics-data">
            <AiOutlineDownload className="exam-stat-title-model" />
            <span className="exam-stat-title-model">Model Download: </span>
            <span className="exam-stat-number">{modelDownload}</span>
          </h6>
          <h6 className="grade-exam-statics-data">
            <AiOutlineDownload className="exam-stat-title-others" />
            <span className="exam-stat-title-others">Others Download: </span>
            <span className="exam-stat-number">{othersDownload}</span>{" "}
          </h6>
          <h6 className="grade-exam-statics-data">
            <AiOutlineDownload className="exam-stat-title-main" />
            <span className="exam-stat-title-main">Total Download: </span>
            <span className="exam-stat-number">
              {mainDownload + modelDownload + othersDownload}
            </span>{" "}
          </h6>
        </div>
      </div>
    </div>
  );
}

export default GradeStaticsBox;
