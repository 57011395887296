function StaticsBox({ title, type, data, icon }) {
  return (
    <div className={"statics-box"}>
      <h2 className={`statics-title statics-title-${type}`}>
        {icon} {title}
      </h2>
      <h2 className="statics-data">{data}</h2>
    </div>
  );
}

export default StaticsBox;
