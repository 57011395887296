import { createContext, useCallback, useContext, useReducer } from "react";
import getAdmin from "../util/getAdminFromStorage";
import {
  FETCH_ACTIVITIES,
  FETCH_DOWNLOAD,
  FETCH_PAYMENT_DETAIL,
  FETCH_SUBSCRIPTION,
  FETCH_USERS,
  LOG_ADMIN,
  LOGOUT_ADMIN,
} from "./actions";
import saveAdmin from "../util/saveAdminToStorage";
import { saveAccessToken } from "../util/accessToken";
import axios from "axios";
import { toast } from "react-toastify";
import { logoutAdminTotally } from "../util/logoutAdminTotally";

const AppContext = createContext();

// Define initial state
const initialState = {
  admin: getAdmin(),
  paymentDetail: null,
  users: null,
  subscriptions: null,
  download: null,
  activities: null,
};
// Define reducer function
const appReducer = (state, action) => {
  switch (action.type) {
    case LOG_ADMIN:
      return { ...state, admin: action.payload };
    case LOGOUT_ADMIN:
      return { ...state, admin: null };
    case FETCH_PAYMENT_DETAIL:
      return { ...state, paymentDetail: action.payload };
    case FETCH_USERS:
      return { ...state, users: action.payload };
    case FETCH_ACTIVITIES:
      return { ...state, activities: action.payload };
    case FETCH_SUBSCRIPTION:
      return { ...state, subscriptions: action.payload };
    case FETCH_DOWNLOAD:
      return { ...state, download: action.payload };
    default:
      return state;
  }
};

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const logAdmin = (admin, accessToken) => {
    saveAdmin(admin);
    saveAccessToken(accessToken);
    dispatch({ type: LOG_ADMIN, payload: admin });
  };
  const logoutAdmin = (admin) => {
    logoutAdminTotally();
    dispatch({ type: LOGOUT_ADMIN });
  };

  const fetchPaymentData = useCallback(async (admin) => {
    try {
      const paymentDetail = await axios.get("/admin/api/payment-details");
      dispatch({
        type: FETCH_PAYMENT_DETAIL,
        payload: paymentDetail?.data?.paymentDetails,
      });
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        logoutAdmin();
        toast.warn("Your loggin session is expired");
        return;
      }

      toast.error(err.message);
    }
  }, []);

  const fetchUsers = useCallback(async (admin) => {
    try {
      const resp = await axios.get("/admin/api/users");
      dispatch({
        type: FETCH_USERS,
        payload: resp?.data?.users,
      });
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        console.log(err);
        logoutAdmin();
        toast.warn("Your loggin session is expired");
        return;
      }

      toast.error(err.message);
    }
  }, []);
  const fetchActivities = useCallback(async (admin) => {
    try {
      const resp = await axios.get("/admin/api/activities");
      dispatch({
        type: FETCH_ACTIVITIES,
        payload: resp?.data?.activities,
      });
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        console.log(err);
        logoutAdmin();
        toast.warn("Your loggin session is expired");
        return;
      }

      toast.error(err.message);
    }
  }, []);
  const fetchSubscripions = useCallback(async (admin) => {
    try {
      const resp = await axios.get("/admin/api/subscriptions");
      console.log(resp);
      dispatch({
        type: FETCH_SUBSCRIPTION,
        payload: resp?.data?.subscriptions,
      });
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        console.log(err);
        logoutAdmin();
        toast.warn("Your loggin session is expired");
        return;
      }

      toast.error(err.message);
    }
  }, []);

  const fetchDownload = useCallback(async (admin) => {
    try {
      const resp = await axios.get("/admin/api/app-download");
      dispatch({
        type: FETCH_DOWNLOAD,
        payload: resp?.data?.download,
      });
    } catch (err) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        console.log(err);
        logoutAdmin();
        toast.warn("Your loggin session is expired");
        return;
      }

      toast.error(err.message);
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        state,
        logAdmin,
        logoutAdmin,
        fetchPaymentData,
        fetchUsers,
        fetchSubscripions,
        fetchDownload,
        fetchActivities,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useProviderContext = () => {
  return useContext(AppContext);
};
