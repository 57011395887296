import React, { useEffect } from "react";
import { BsFillMenuButtonWideFill, BsAlarm } from "react-icons/bs";
import moment from "moment";

import {
  AiOutlineBranches,
  AiOutlineReload,
  AiOutlineUser,
} from "react-icons/ai";
import { useProviderContext } from "../../provider/appProvider";
import Loader from "../../common/Loader";
import BasicTitle from "../../common/BasicTitle";
import { useNavigate } from "react-router-dom";
import { RiDownloadCloudFill } from "react-icons/ri";

function RecentDownloaders(props) {
  const {
    state: { users, activities },
    fetchUsers,
    fetchActivities,
  } = useProviderContext();

  const refreshData = () => {
    fetchUsers();
    fetchActivities();
  };
  useEffect(() => {
    fetchUsers();
    fetchActivities();
  }, [fetchUsers, fetchActivities]);

  return (
    <div className="user-detail-page">
      <div className="user-detail-content-wrapper">
        <div className="user-detail-header">
          <h1 className="common-pages-title"> Recent Downloaders </h1>
          <div className="user-detail-refresh-wrapper">
            <button
              className="user-detail-refresh-icon-button"
              onClick={refreshData}
            >
              Refresh
              <AiOutlineReload className="user-detail-refresh-icon" />
            </button>
          </div>
        </div>

        {users == null || activities == null ? (
          <div className="page-loading-wrapper">
            <Loader width={200} height={200} color={"red"} />
          </div>
        ) : users.length === 0 ? (
          <div className="page-loading-wrapper">
            <div className="no-data-wrapper">
              <BsFillMenuButtonWideFill className="no-data-icon" />

              <BasicTitle title="There is no user detail in the docket" />
            </div>
          </div>
        ) : (
          activities
            .sort((a, b) => {
              a = JSON.parse(a.downloadInformation) ?? [];
              b = JSON.parse(b.downloadInformation) ?? [];
              a.sort((a, b) => b.date - a.date);
              b.sort((a, b) => b.date - a.date);

              a = a[0] === undefined ? 0 : a[0].date;
              b = b[0] === undefined ? 0 : b[0].date;
              console.log(a, b);
              return b - a;
            })
            .map((activity, i) => {
              const user = users.filter(
                (user) => user?.uid === activity?.uid
              )[0];

              if (user) {
                return (
                  <RecentDownloader
                    key={activity.uid + "-" + Math.random()}
                    user={user}
                    activity={activity}
                  />
                );
              }
              return <React.Fragment key={Math.random()}> </React.Fragment>;
            })
        )}
      </div>
    </div>
  );
}

export default RecentDownloaders;

function RecentDownloader({
  user: { fullname, phone, grade, createdAt, uid, isSubscribed },
  activity,
}) {
  const navigator = useNavigate();
  const downloadInformation = JSON.parse(activity?.downloadInformation);
  const timing =
    moment(
      new Date(downloadInformation?.sort((a, b) => a - b)[0]?.date)
    ).fromNow() === "Invalid date"
      ? "No download"
      : moment(
          new Date(
            downloadInformation?.sort((a, b) => b.date - a.date)[0]?.date
          )
        ).fromNow();

  return (
    <div
      className="user-detail-card"
      onClick={() => navigator("/users/" + uid)}
    >
      <div className="upper-user-card">
        <h5 className="user-card-name">
          <AiOutlineUser className="user-card-name-icon" />
          {fullname}
          <span className="user-card-grade">
            {" "}
            Grade:
            {grade === "grade8"
              ? "8"
              : grade === "grade12n"
              ? "12 Natural"
              : grade === "grade12s"
              ? "12 Social"
              : grade}{" "}
            <AiOutlineBranches className="user-card-grade-icon" />{" "}
          </span>
        </h5>
        <div className="user-card-time">
          {" "}
          <BsAlarm className="user-card-time-icon" /> {timing}
        </div>
      </div>
      <div className="bottom-user-card">
        <div className="user-card-email">
          Download in 10 min:{" "}
          {downloadInformation?.filter((download) => {
            return moment(new Date())
              .subtract({ minute: 10 })
              .isBefore(moment(new Date(download?.date)));
          }).length ?? 0}{" "}
        </div>
        <div className="user-card-email">
          <RiDownloadCloudFill /> {downloadInformation?.length ?? 0}
        </div>
      </div>

      {isSubscribed === true && (
        <div className={"verification-flag verification-flag-" + true}>
          {" "}
          Subscribed
        </div>
      )}
    </div>
  );
}
