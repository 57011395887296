import React from "react";
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Loader from "../../common/Loader";
import BasicTitle from "../../common/BasicTitle";
import {
  AiFillPhone,
  AiFillUnlock,
  AiOutlineBook,
  AiOutlineDownload,
  AiOutlineFieldTime,
  AiOutlineMail,
  AiOutlineUser,
  AiOutlineReload,
  AiOutlineLogin,
  AiOutlineClear,
  AiOutlineBank,
} from "react-icons/ai";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

import moment from "moment";
import "./singleUser.css";
import axios from "axios";
import { toast } from "react-toastify";
function SingleUser(props) {
  const { uid } = useParams();
  const { data, loading, error, refreshData } = useFetch(
    "/admin/api/users/" + uid
  );
  return (
    <div className="user-detail-page">
      <div className="user-detail-content-wrapper">
        {loading && (
          <div className="page-loading-wrapper">
            <Loader width={200} height={200} color={"#7175D6"} />
          </div>
        )}
        {data && (
          <SinglePamentCard data={data?.user} refreshData={refreshData} />
        )}
        {error && (
          <div className="page-loading-wrapper">
            <div className="no-data-wrapper">
              <BsFillMenuButtonWideFill className="no-data-icon" />

              <BasicTitle title="Something went wrong!!" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SingleUser;

function SinglePamentCard({
  data: {
    fullname,
    grade,
    phone,
    email,
    createdAt,
    uid,
    freeTrialEnds,
    block,
    device,
    activity,
    subscription,
    isVerified,
  },
  refreshData,
}) {
  const structureInfo =
    activity?.structureInfo == null
      ? null
      : JSON.parse(activity?.structureInfo);
  console.log(structureInfo);
  const { expirationDate, grade8, grade12n, grade12s } = subscription ?? {};
  const bankRequestInformation = activity?.bankRequestInformation
    ? JSON.parse(activity?.bankRequestInformation)
    : null;
  const handleVerification = async (plan) => {
    try {
      await axios.post("/admin/api/verify-payment?freePromo=freePromotion", {
        uid: uid,
        plan,
      });
      refreshData();
    } catch (err) {
      toast.error(err?.message ?? "Something went error!!");
    }
  };

  const howMuchLeft = (date) => {
    if (!date) return "not subscribed yet";
    const difference = moment(new Date(date)).diff(new Date());
    if (difference <= 0) return "It just past";
    const inDays = Math.ceil(difference / (1000 * 60 * 60 * 24));
    return `${inDays} days left`;
  };
  const resetUserDevice = async (uid) => {
    try {
      await axios.post("/admin/api/reset-user-device", { uid });
      refreshData();
    } catch (err) {
      toast.error(err?.response?.data?.message ?? "Something went wrong");
    }
  };
  const handleBlockUser = async (uid) => {
    try {
      await axios.post("/admin/api/block-user", { uid });
      refreshData();
    } catch (err) {
      toast.error(err?.response?.data?.message ?? "Something went wrong");
    }
  };

  const handleVerifyUser = async (uid) => {
    try {
      await axios.post("/admin/api/verify-user", { uid });
      refreshData();
    } catch (err) {
      toast.error(err?.response?.data?.message ?? "Something went wrong");
    }
  };

  return (
    <div className="single-user-card">
      <div className="single-user-card-profile-wrapper">
        <div className="profile-avatar-wrapper">
          <AiOutlineUser className="profile-avatar" />
          <div className="profile-fullname">{fullname}</div>
          <div className={"verification-flag verification-flag-" + isVerified}>
            {" "}
            {isVerified ? "Verified" : "Not Verified"}{" "}
          </div>
        </div>

        <div className="profile-grade">{grade}</div>
        <button
          className="payment-detail-refresh-icon-button"
          onClick={refreshData}
        >
          Refresh
          <AiOutlineReload className="payment-detail-refresh-icon" />
        </button>
      </div>

      <div className="profile-info-table">
        <BasicTitle title={"User detail"} />
        <Tile
          title={"phone"}
          info={phone}
          icon={<AiFillPhone className="tile-icon" />}
        />
        <Tile
          title={"Email"}
          info={email}
          icon={<AiOutlineMail className="tile-icon" />}
        />
        <Tile
          title={"Bank Request"}
          info={
            bankRequestInformation == null
              ? 0
              : bankRequestInformation?.length + " times "
          }
          icon={<AiOutlineBank className="tile-icon" />}
        />
        <Tile
          title={"Verification Code"}
          info={
            activity?.requestCodeInformation == null
              ? 0
              : activity?.requestCodeInformation?.length + " times "
          }
          icon={<AiOutlineDownload className="tile-icon" />}
        />
        <Tile
          title={"Download Count"}
          info={activity?.downloadInformation?.length ?? 0}
          icon={<AiOutlineDownload className="tile-icon" />}
        />
        <Tile
          title={"Last download"}
          info={
            activity?.downloadInformation
              ? moment(
                  new Date(
                    activity?.downloadInformation?.sort(
                      (a, b) => b.date - a.date
                    )[0]?.date
                  )
                ).fromNow()
              : "No Download"
          }
          icon={<AiOutlineDownload className="tile-icon" />}
        />
        <Tile
          title={"Last login"}
          info={
            activity?.loginInformation
              ? moment(
                  new Date(activity?.loginInformation?.sort((a, b) => b - a)[0])
                ).fromNow()
              : "No Login"
          }
          icon={<AiOutlineLogin className="tile-icon" />}
        />
        <Tile
          title={"Structure Version"}
          info={structureInfo?.structureVersion ?? "null"}
          icon={<AiOutlineDownload className="tile-icon" />}
        />
        <Tile
          title={"Structure Update"}
          info={
            structureInfo?.date == null
              ? "null"
              : moment(new Date(structureInfo?.date)).fromNow()
          }
          icon={<AiOutlineDownload className="tile-icon" />}
        />

        <Tile
          title={"Acc. Created At"}
          info={moment(createdAt).fromNow()}
          icon={<AiOutlineFieldTime className="tile-icon" />}
        />

        <BasicTitle title={"Subscription"} />
        <Tile
          title={"Grade 8"}
          info={
            grade8 ? (
              "Subscribed"
            ) : (
              <TileButton
                handleVerification={() => handleVerification("grade8")}
                plan={"grade8"}
              />
            )
          }
          icon={<AiOutlineBook className="tile-icon" />}
        />
        <Tile
          title={"Grade 12 natural"}
          info={
            grade12n ? (
              "Subscribed"
            ) : (
              <TileButton
                handleVerification={() => handleVerification("grade12n")}
                plan={"grade12n"}
              />
            )
          }
          icon={<AiOutlineBook className="tile-icon" />}
        />
        <Tile
          title={"Grade 12 social"}
          info={
            grade12s ? (
              "Subscribed"
            ) : (
              <TileButton
                handleVerification={() => handleVerification("grade12s")}
                plan={"grade12s"}
              />
            )
          }
          icon={<AiOutlineBook className="tile-icon" />}
        />

        <BasicTitle title={"Access "} />

        <Tile
          title={"Account expiration:"}
          info={howMuchLeft(expirationDate)}
          icon={<AiOutlineFieldTime className="tile-icon" />}
        />
        <Tile
          title={"Freetrial:"}
          info={howMuchLeft(freeTrialEnds)}
          icon={<AiOutlineFieldTime className="tile-icon" />}
        />
        <BasicTitle title={"Device "} />
        <Tile
          title={"Device:"}
          info={device ?? "No device associated"}
          icon={<AiOutlineFieldTime className="tile-icon" />}
        />
        {device !== null && (
          <div className="user-detail-refresh-wrapper">
            {" "}
            <button
              className="payment-detail-refresh-icon-button"
              onClick={() => resetUserDevice(uid)}
            >
              Reset user device
              <AiOutlineClear className="payment-detail-refresh-icon" />
            </button>{" "}
          </div>
        )}

        <BasicTitle title={"Verify User - To verify users phone"} />
        <button
          className={
            isVerified
              ? "user-detail-block-icon-button"
              : "user-detail-unblock-icon-button"
          }
          onClick={() => handleVerifyUser(uid)}
        >
          {isVerified ? "UnVerify user" : "Verify user"}
          <IoMdCheckmarkCircleOutline className="payment-detail-refresh-icon" />
        </button>

        <BasicTitle title={"Access Denial - To block users from using "} />
        <button
          className={
            !block
              ? "user-detail-block-icon-button"
              : "user-detail-unblock-icon-button"
          }
          onClick={() => handleBlockUser(uid)}
        >
          {block ? "Unblock user" : "Block user"}
          <AiOutlineClear className="payment-detail-refresh-icon" />
        </button>
      </div>
    </div>
  );
}

function Tile({ title, info, icon }) {
  return (
    <div className="profile-info-tile">
      <div>
        {icon}
        <span className="tile-title">{title}:</span>
      </div>
      <span className="tile-data"> {info} </span>
    </div>
  );
}

function TileButton({ handleVerification, plan }) {
  return (
    <button className="verify-button" onClick={handleVerification}>
      <span className="verify-button-text"> Open acccout </span>
      <AiFillUnlock className="verify-button-icon" />
    </button>
  );
}
