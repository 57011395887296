import React, { useEffect } from "react";
import {
  AiOutlineDollarCircle,
  AiOutlineBook,
  AiOutlineDashboard,
  AiOutlineDollar,
  AiOutlineGift,
  AiOutlinePercentage,
  AiOutlinePlusCircle,
  AiOutlineReload,
  AiOutlineUser,
} from "react-icons/ai";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

import { useProviderContext } from "../../provider/appProvider";
import "./userStatistics.css";
import Loader from "../../common/Loader";

import moment from "moment";
import StaticsBox from "../../common/Statistics";
function UserStatistics(props) {
  const {
    state: { users, subscriptions },
    fetchUsers,
    fetchSubscripions,
  } = useProviderContext();
  const refreshData = () => {
    fetchUsers();
    fetchSubscripions();
  };
  useEffect(() => {
    fetchUsers();
    fetchSubscripions();
  }, [fetchUsers, fetchSubscripions]);
  const handleVersion = (users) => {
    const versions = [
      ...new Set(users.map((user) => user.appVersion ?? "none")),
    ].filter((version) => version.length > 4);
    return versions;
  };
  return (
    <div className="payment-detail-page">
      <div className="payment-detail-content-wrapper">
        <div className="payment-detail-header">
          <h1 className="common-pages-title"> User statics </h1>
          <div className="payment-detail-refresh-wrapper">
            <button
              className="payment-detail-refresh-icon-button"
              onClick={refreshData}
            >
              Refresh
              <AiOutlineReload className="payment-detail-refresh-icon" />
            </button>
            <AiOutlineDashboard className="payment-detail-icon" />
          </div>
        </div>
        {users == null || subscriptions == null ? (
          <div className="page-loading-wrapper">
            <Loader width={200} height={200} color={"red"} />
          </div>
        ) : (
          <>
            <div className="statics-title-top">Users</div>
            <div className="statics-wrapper">
              <StaticsBox
                data={users.length}
                title={"All Total Users"}
                type={1}
                icon={<AiOutlineUser className="statics-icon statics-icon-1" />}
              />
              <StaticsBox
                data={users.filter((user) => user.isSubscribed).length}
                title={"Subscribed Users"}
                type={3}
                icon={
                  <AiOutlineDollarCircle className="statics-icon statics-icon-3" />
                }
              />
              <StaticsBox
                data={
                  (users.filter((user) => user.isSubscribed).length /
                    users.length) *
                    100 +
                  "%"
                }
                title={"Percentage Subscription"}
                type={2}
                icon={
                  <AiOutlinePercentage className="statics-icon statics-icon-2" />
                }
              />
              <StaticsBox
                data={
                  users.filter((user) =>
                    moment(user.freeTrialEnds).isAfter(new Date())
                  ).length
                }
                title={"Freetrial Users"}
                type={1}
                icon={<AiOutlineGift className="statics-icon statics-icon-1" />}
              />
              <StaticsBox
                data={users.filter((user) => user.isVerified).length}
                title={"Verified Users"}
                type={2}
                icon={
                  <IoMdCheckmarkCircleOutline className="statics-icon statics-icon-2" />
                }
              />

              <StaticsBox
                data={
                  Math.round(
                    (users.filter((user) => user.isVerified).length /
                      users.length) *
                      100
                  ) + "%"
                }
                title={"Verified percentage"}
                type={1}
                icon={
                  <IoMdCheckmarkCircleOutline className="statics-icon statics-icon-1" />
                }
              />
            </div>

            <div className="statics-title-top">Vesrion Statics</div>
            <div className="statics-wrapper">
              {handleVersion(users).map((version) => {
                return (
                  <div className={"statics-box"} key={version}>
                    <h2 className={`statics-title statics-title-1`}>
                      <AiOutlineReload className="statics-icon statics-icon-1" />
                      Version control
                    </h2>
                    <div className="version-tile">
                      <div className="version-head">
                        <AiOutlineReload className="statics-icon statics-icon-1" />
                        <div className="version-name"> {version} </div>
                      </div>
                      <div className="version-count">
                        {
                          users?.filter((user) => user?.appVersion === version)
                            ?.length
                        }{" "}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="statics-title-top">Structure Vesrion</div>
            <div className="statics-wrapper">
              <StaticsBox
                data={
                  users.filter((user) => {
                    const structureInfo =
                      user.activity?.structureInfo == null
                        ? null
                        : JSON.parse(user.activity?.structureInfo);
                    return structureInfo?.structureVersion !== 1;
                  }).length
                }
                title={"Version 0"}
                type={1}
                icon={
                  <AiOutlineReload className="statics-icon statics-icon-1" />
                }
              />

              <StaticsBox
                data={
                  users.filter((user) => {
                    console.log(user);
                    const structureInfo =
                      user.activity?.structureInfo == null
                        ? null
                        : JSON.parse(user.activity?.structureInfo);
                    return structureInfo?.structureVersion === 1;
                  }).length
                }
                title={"Version 1"}
                type={1}
                icon={
                  <AiOutlineReload className="statics-icon statics-icon-1" />
                }
              />
            </div>

            <div className="statics-title-top">Periodic registration</div>
            <div className="statics-wrapper">
              <StaticsBox
                data={
                  users.filter((user) =>
                    moment(user?.createdAt).isAfter(
                      moment(new Date()).subtract({ day: 7 })
                    )
                  ).length
                }
                title={"Weekly registration"}
                type={1}
                icon={
                  <AiOutlinePlusCircle className="statics-icon statics-icon-1" />
                }
              />
              <StaticsBox
                data={
                  users.filter((user) =>
                    moment(user.createdAt).isAfter(
                      moment(new Date()).subtract({ day: 30 })
                    )
                  ).length
                }
                title={"Monthly registration"}
                type={3}
                icon={
                  <AiOutlinePlusCircle className="statics-icon statics-icon-3" />
                }
              />
            </div>

            <div className="statics-title-top">Periodic subscription</div>
            <div className="statics-wrapper">
              <StaticsBox
                data={
                  subscriptions.filter((subscription) => {
                    return moment(
                      subscription?.subscriptionDate ??
                        new Date("2000-03-07T23:57:50.000Z")
                    ).isAfter(moment(new Date()).subtract({ day: 7 }));
                  }).length
                }
                title={"Weekly subscription"}
                type={2}
                icon={
                  <AiOutlineDollar className="statics-icon statics-icon-2" />
                }
              />
              <StaticsBox
                data={
                  subscriptions.filter((subscription) => {
                    return moment(
                      subscription?.subscriptionDate ??
                        new Date("2000-03-07T23:57:50.000Z")
                    ).isAfter(moment(new Date()).subtract({ day: 30 }));
                  }).length
                }
                title={"Monthly subscription"}
                type={1}
                icon={
                  <AiOutlineDollar className="statics-icon statics-icon-1" />
                }
              />
            </div>

            <div className="statics-title-top">Grade</div>
            <div className="statics-wrapper">
              <StaticsBox
                data={users.filter((user) => user.grade === "grade8").length}
                title={"Grade 8 students"}
                type={3}
                icon={<AiOutlineBook className="statics-icon statics-icon-3" />}
              />
              <StaticsBox
                data={users.filter((user) => user.grade === "grade12n").length}
                title={"Grade 12 natural students"}
                type={1}
                icon={<AiOutlineBook className="statics-icon statics-icon-1" />}
              />
              <StaticsBox
                data={users.filter((user) => user.grade === "grade12s").length}
                title={"Grade 12 social students"}
                type={2}
                icon={<AiOutlineBook className="statics-icon statics-icon-2" />}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default UserStatistics;
