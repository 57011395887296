import React, { useEffect } from "react";
import {
  AiOutlineDashboard,
  AiOutlineReload,
  AiOutlineDownload,
} from "react-icons/ai";
import { useProviderContext } from "../../provider/appProvider";
import Loader from "../../common/Loader";

import moment from "moment";
import StaticsBox from "../../common/Statistics";
function AppDownload(props) {
  const {
    state: { download },
    fetchDownload,
  } = useProviderContext();
  const refreshData = () => {
    fetchDownload();
  };
  useEffect(() => {
    fetchDownload();
  }, [fetchDownload]);

  return (
    <div className="payment-detail-page">
      <div className="payment-detail-content-wrapper">
        <div className="payment-detail-header">
          <h1 className="common-pages-title"> App Download </h1>
          <div className="payment-detail-refresh-wrapper">
            <button
              className="payment-detail-refresh-icon-button"
              onClick={refreshData}
            >
              Refresh
              <AiOutlineReload className="payment-detail-refresh-icon" />
            </button>
            <AiOutlineDashboard className="payment-detail-icon" />
          </div>
        </div>
        {download == null ? (
          <div className="page-loading-wrapper">
            <Loader width={200} height={200} color={"red"} />
          </div>
        ) : (
          <>
            <div className="statics-title-top">App download Statistics </div>
            <div className="statics-wrapper">
              <StaticsBox
                data={download.count}
                title={"Total Download"}
                type={1}
                icon={
                  <AiOutlineDownload className="statics-icon statics-icon-1" />
                }
              />
              <StaticsBox
                data={
                  download?.dates?.filter((date) =>
                    moment(date).isAfter(
                      moment(new Date()).subtract({ days: 7 })
                    )
                  ).length
                }
                title={"Weekly Download"}
                type={3}
                icon={
                  <AiOutlineDownload className="statics-icon statics-icon-3" />
                }
              />
              <StaticsBox
                data={
                  download?.dates?.filter((date) =>
                    moment(date).isAfter(
                      moment(new Date()).subtract({ days: 30 })
                    )
                  ).length
                }
                title={"Monthly Download"}
                type={2}
                icon={
                  <AiOutlineDownload className="statics-icon statics-icon-2" />
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AppDownload;
