import React, { useEffect, useState } from "react";
import "./allUsers.css";
import { BsFillMenuButtonWideFill, BsAlarm } from "react-icons/bs";
import moment from "moment";

import {
  AiOutlineBranches,
  AiOutlineContacts,
  AiOutlineMobile,
  AiOutlineReload,
  AiOutlineSearch,
  AiOutlineUser,
} from "react-icons/ai";
import { useProviderContext } from "../../provider/appProvider";
import Loader from "../../common/Loader";
import BasicTitle from "../../common/BasicTitle";
import { useNavigate } from "react-router-dom";

function AlllUsers(props) {
  const {
    state: { users },
    fetchUsers,
  } = useProviderContext();
  const [liveSearch, setLiveSearch] = useState(true);
  const [search, setSearch] = useState("");
  const [clicked, setClicked] = useState(false);

  const refreshData = () => {
    fetchUsers();
  };
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);
  const handleSearch = (e) => {
    e.preventDefault();
    if (!clicked) setClicked(true);
  };

  const handleVcfDownload = async (users) => {
    downloadVCF(users);
  };

  const downloadVCF = (uses) => {
    const vcards = users.map((user) => {
      return `BEGIN:VCARD\nVERSION:2.1\nN:;${user.fullname};;;\nFN:${user.fullname}\nTEL;CELL:${user.phone}\nEMAIL;INTERNET:${user.email}\nNOTE:${user.grade}\nEND:VCARD`;
    });

    const blob = new Blob([vcards], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Freedom-learn-users.vcf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const processedData = (users) => {
    let processed = users.sort((a, b) => a.createdAt - b.createdAt);

    if (liveSearch || clicked) {
      processed = processed.filter(
        (user) =>
          user.fullname.toLowerCase().includes(search.toLowerCase()) ||
          user.phone.toLowerCase().includes(search.toLowerCase()) ||
          user.email.toLowerCase().includes(search.toLowerCase())
      );
    }

    return processed;
  };
  return (
    <div className="user-detail-page">
      <div className="user-detail-content-wrapper">
        <div className="user-detail-header">
          <h1 className="common-pages-title"> user detail </h1>
          <div className="user-detail-refresh-wrapper">
            <button
              className="user-detail-refresh-icon-button"
              onClick={refreshData}
            >
              Refresh
              <AiOutlineReload className="user-detail-refresh-icon" />
            </button>
          </div>
        </div>
        <form className="search-tool-wrapper">
          <input
            type="checkbox"
            checked={liveSearch || clicked}
            onChange={(e) => setLiveSearch((state) => !state)}
            className="live-search-checkbox"
          />
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="user-search-input"
            placeholder="search"
          />
          <button
            type="submit"
            className="search-submit"
            onClick={handleSearch}
            disabled={liveSearch}
          >
            {" "}
            Search <AiOutlineSearch className="search-icon" />
          </button>
        </form>
        <div className="vcf-exporter-holder">
          <button
            type="submit"
            className="vcf-export-button"
            onClick={() => handleVcfDownload(users)}
          >
            {" "}
            <div> Export VCF </div>{" "}
            <div>
              {" "}
              <AiOutlineContacts className="vcf-export-icon" />{" "}
            </div>
          </button>
        </div>
        {users == null ? (
          <div className="page-loading-wrapper">
            <Loader width={200} height={200} color={"red"} />
          </div>
        ) : users.length === 0 ? (
          <div className="page-loading-wrapper">
            <div className="no-data-wrapper">
              <BsFillMenuButtonWideFill className="no-data-icon" />

              <BasicTitle title="There is no user detail in the docket" />
            </div>
          </div>
        ) : (
          processedData(users)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((user) => {
              return (
                <UserDetailCard
                  key={user.uid}
                  user={user}
                  search={liveSearch || clicked ? search : ""}
                />
              );
            })
        )}
      </div>
    </div>
  );
}

export default AlllUsers;

function UserDetailCard({
  user: {
    fullname,
    phone,
    grade,
    createdAt,
    uid,
    email,
    isVerified,
    freeTrialEnds,
  },
  search,
}) {
  const navigator = useNavigate();
  const timing = moment(createdAt).fromNow();
  return (
    <div
      className="user-detail-card"
      onClick={() => navigator("/users/" + uid)}
    >
      <div className="upper-user-card">
        <h5 className="user-card-name">
          <AiOutlineUser className="user-card-name-icon" />
          <SearchGoogle from={fullname} search={search} />
          <span className="user-card-grade">
            {" "}
            Grade:
            {grade === "grade8"
              ? "8"
              : grade === "grade12n"
              ? "12 Natural"
              : grade === "grade12s"
              ? "12 Social"
              : grade}{" "}
            <AiOutlineBranches className="user-card-grade-icon" />{" "}
          </span>
        </h5>
        <div className="user-card-time">
          {" "}
          <BsAlarm className="user-card-time-icon" /> {timing}
        </div>
      </div>
      <div className="bottom-user-card">
        <div className="user-card-phone">
          <SearchGoogle from={phone} search={search} />
        </div>

        <div className="user-card-email">
          <SearchGoogle from={email} search={search} />{" "}
        </div>
      </div>
      <div className={"verification-flag verification-flag-" + isVerified}>
        {" "}
        {isVerified ? "Verified" : "Not Verified"}{" "}
      </div>
      {createdAt === freeTrialEnds && (
        <div className={"free-trial-decline-indicators"}>
          <AiOutlineMobile color="white" />
        </div>
      )}
    </div>
  );
}

function SearchGoogle({ from, search }) {
  console.log(
    from.toLowerCase().split(search.toLowerCase()).join(`|${search}|`)
  );
  return (
    <>
      {search.length
        ? from
            .toLowerCase()
            .split(search.toLowerCase())
            .join(`|${search}|`)
            .split("|")
            .map((term, i) => {
              return (
                <span
                  className={
                    search.toLowerCase() === term.toLowerCase()
                      ? "search-term-mark"
                      : ""
                  }
                  key={term + "-" + i}
                >
                  {term}
                </span>
              );
            })
        : from}
    </>
  );
}
